import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { Navigate, useLocation } from 'react-router-dom'
import { rootStore             } from 'store/RootStore'
import { getLocalStorageItem   } from 'utils/localStorage'

//import { AuthContainer   } from './containers/Auth/Auth'
import { AllRoutesRender } from 'AllRoutesRender'
import { isOuter         } from 'data/common/routes'
import { Loader          } from 'components/Common/Loader'

import { ACCESS_TOKEN    } from 'data/constants/globalConstants'

const isExistToken = getLocalStorageItem (
   ACCESS_TOKEN,
   null
)

export const RouteChecker = observer (() => {
   
   const { pathname } = useLocation ()

   useEffect(() => {
      if (isExistToken) rootStore.currentUserStore.getCurrentUserData ()
   }, [])

   if (rootStore.currentUserStore.currentUserIsLoading) {
      return <Loader />
   }

   if (!rootStore.currentUserStore.currentUserExists &&
       !isOuter (pathname) &&
       !isExistToken)
      return <Navigate to = '/login' />

   if (rootStore.currentUserStore.currentUserExists &&
       isOuter (pathname))
      return <Navigate to = '/' />

   return (
      <>
         <AllRoutesRender /> 
         { /* { isOuter (pathname) 
           ? <AuthContainer   /> 
           : <AllRoutesRender /> 
         } */ }
      </>
   )
})
