import React, { useState, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { observer  } from 'mobx-react'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TCustomBlock } from 'models/ControlPanel'

import {
   Card,
   CardHeader,
   Heading,
} from 'grommet'

import { TargetEnable } from './components/TargetEnable'
import { TargetValue  } from './components/TargetValue'

export const CustomTargetBlock = observer (({ block }: { block: TCustomBlock }) => {

   const { idProject } = useParams ()
   const [edit          , setEdit          ] = useState (false)
   const [targetValue   , setTargetValue   ] = useState <number>  ()
   const [targetIsEnable, setTargetIsEnable] = useState <boolean> ()

   const sendCommand = useCallback (async (
      alias  : string,
      command: number,
      value  : number
   ) => {
      controllerStateStore.setCommand (Number (idProject), block.alias, command, value)
   }, [block.alias, idProject])

   useEffect (() => {

      setTargetValue    (controllerStateStore.state
      .find (option => new RegExp (block.id, 'i').test (option.name) && /Значение/.test  (option.name))?.value)  

      setTargetIsEnable (!!controllerStateStore.state
      .find (option => new RegExp (block.id, 'i').test (option.name) && /Состояние/.test (option.name))?.value)

   }, [controllerStateStore.state, block])

   //console.log (targetIsEnable, targetValue)

   if (targetIsEnable || targetValue) return (

      <Card
         pad    = "medium"
         border = { { color: 'brand', size: 'xsmall' } }
      >
         <CardHeader justify = "center">
            <Heading
               level  = { 3 }
               margin = { { top: 'xxsmall', bottom: 'none' } }
            >
               { block.label }
            </Heading>
         </CardHeader>

         { !edit &&
            <TargetEnable
               block             = { block }
               targetValue       = { targetValue }
               targetIsEnable    = { targetIsEnable }
               setTargetIsEnable = { setTargetIsEnable }
               setEdit           = { setEdit }
               sendCommand       = { sendCommand }
            />
         }

         { edit &&
            <TargetValue
               block       = { block }
               targetValue = { targetValue }
               setEdit     = { setEdit }
            />
         }
      </Card>
   )
})