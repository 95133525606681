import React, { useState, useEffect } from 'react';

import { Box, Button, Heading, Layer } from 'grommet';
import { Close, Contract, Expand } from 'grommet-icons';
import { HeightType, WidthType } from 'grommet/utils';

export const Modal = ({ 
   modal,
   setModal,
   heading,
   subheading,
   width,
   height,
   children
} : {
   modal      : boolean,
   setModal   : React.Dispatch <React.SetStateAction <boolean>>,
   heading    : string,
   subheading?: string,
   width?     : WidthType,
   height?    : HeightType,
   children   : JSX.Element,
}) => {

   const [bug   , setBug   ] = useState (true) //for bug of grommet
   const [expand, setExpand] = useState (false)

   useEffect (() => { setModal (false) }, [bug])

   return (
      <>

         { modal && (

            <Layer
               onEsc          = { () => setModal (false) }
               onClickOutside = { () => setModal (false) }
               full           = { expand }
               animation      = "fadeIn"
            >
               <Box
                  width  = { expand ? 'auto' : width ?? 'large' }
                  height = { expand ? 'auto' : height ?? '550px' }
                  fill   = { expand }
                  pad    = { {
                     top       : 'small',
                     horizontal: 'medium',
                     bottom    : 'medium'
                  } }
                  round
               >
                  <Box
                     justify   = 'between'
                     direction = "row"
                  >
                     <Heading
                        margin = { { vertical: '10px' } }
                        level  = { 2 }
                     >
                        { heading }
                     </Heading>

                     <Box direction = 'row'>
                        <Button
                           icon    = { expand ? <Contract /> : <Expand /> }
                           onClick = { () => setExpand (!expand) } 
                        />
                        <Button
                           icon    = { <Close /> }
                           onClick = { () => setBug (!bug) } 
                        />
                     </Box>
                  </Box>

                  <Box>
                     <Heading
                        margin = { { top: 'none' } }
                        level  = { 3 }
                     >
                        { subheading }
                     </Heading>
                  </Box>

                  { children }

               </Box>
            </Layer>
         ) }
      </>
   )
}
