import React, { FC } from "react"
import { observer  } from "mobx-react"
import { Navigate  } from "react-router-dom"
import { rootStore } from "store/RootStore"

import { Login         } from "components/Auth/Login"
import { Registration  } from "components/Auth/Registration"
import { ResetPassword } from "components/Auth/ResetPassword"

export const AuthContainer: FC = observer(() => {

   if (rootStore.currentUserStore.currentUserExists) return <Navigate to = "/" />
   if (location.pathname.includes ('/registration')) return <Registration />
   if (location.pathname.includes ('/resetpassword')) return <ResetPassword />
   return <Login />
})
