import React, { useEffect, useState } from 'react'

import {
   Table,
   Button,
   Card,
   CardBody,
   CardHeader,
   Heading,
   TableBody,
   TableCell,
   TableRow,
   Box
} from 'grommet'

import { ControlToggle } from 'components/Library/Controls/ControlToggle'
import { Modal         } from 'components/Library/Modal/Modal'
import { ChangeWiFi    } from './ChangeWiFi'
import { getUnitWifiList } from 'api/setting'

export const WifiSettingCard = ({
   title,
   data,
} : {
   title: string,
   data: any,
}) => {

   const [edit, setEdit] = useState (false)

   useEffect (() => {

      const getWifiList = async () => {
         const wifiList = await getUnitWifiList ()
         
         console.log (wifiList)
      }

      getWifiList ()
   }, [])

   return (
      <>
         <Card
            elevation = 'large'
            pad       = "medium"
         >
            <CardHeader
               style  = { { borderBottom: "1px solid #dfe4ec" } }
            >
               <Heading
                  margin = { {
                     horizontal: 'small',
                     top       : 'none'
                  } }
                  level  = '3'
               >
                  { title }
               </Heading>

               <Box
                  margin = { {
                     horizontal: 'small',
                     bottom    : 'medium'
                  } }
               >
                  <ControlToggle
                     alias       = ''
                     watchdog    = { false }
                     sendCommand = { command => console.log (command) }
                     control     = { {
                        id  : 'R2-13',
                        type: 'Toogle',
                        options: [
                           {
                              state: 0,
                              label: 'Открыта',
                              command: 0x1,
                           },
                           {
                              state: 1,
                              label: 'Закрыта',
                              command: 0x2,
                           }
                        ]
                     } }
                  />
               </Box>
            </CardHeader>

            <CardBody pad = { { top: 'medium' } } >
               <Table style = { { display: 'flex' } } >
                  <TableBody>

                     { data.name && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Режим AP:</strong>
                           </TableCell>

                           <TableCell>
                              <Box align = 'end' >
                                 <ControlToggle
                                    alias       = ''
                                    watchdog    = { false }
                                    sendCommand = { command => console.log (command) }
                                    control     = { {
                                       id  : 'R2-13',
                                       type: 'Toogle',
                                       options: [
                                          {
                                             state: 0,
                                             label: 'Открыта',
                                             command: 0x1,
                                          },
                                          {
                                             state: 1,
                                             label: 'Закрыта',
                                             command: 0x2,
                                          }
                                       ]
                                    } }
                                 />
                              </Box>
                           </TableCell>
                        </TableRow>
                     ) }

                     { data.phone && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Сеть:</strong>
                           </TableCell>

                           <TableCell> { data.phone }</TableCell>
                        </TableRow>
                     ) }
                  </TableBody>
               </Table>
               <Box
                  justify = 'end'
                  align   = 'end'
                  pad     = { { vertical: 'xsmall', horizontal: 'small' } }
                  fill
               >
                  <Button
                     label   = "Изменить"
                     onClick = { () => setEdit (true) }
                  />
               </Box>
            </CardBody>
         </Card>

         <Modal
            modal    = { edit }
            setModal = { setEdit }
            heading  = 'Hастройки WiFi'
            width    = '450px'
            height   = 'auto'
         >
            <ChangeWiFi onClose = { () => setEdit (false) } />
         </Modal>
      </>
   )
}
