import React from 'react'
import { observer } from 'mobx-react'

import { Line } from 'react-chartjs-2'
import zoomPlugin from 'chartjs-plugin-zoom'
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Tooltip,
   LogarithmicScale,
   ChartData,
   Point,
   ScaleOptionsByType,
   CartesianScaleTypeRegistry,
   Legend,
   Title,
} from 'chart.js'

import { Box } from 'grommet'
import { Loader } from 'components/Common/Loader'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'

ChartJS.register (
   CategoryScale,
   LinearScale,
   LogarithmicScale,
   PointElement,
   LineElement,
   Tooltip,
   Legend,
   Title,
   zoomPlugin
)

export const ChartDisplay = observer (({
   loading,
   options
} : {
   loading: boolean,
   options: {
      labels  : ChartData['labels'],
      datasets: ChartData <'line', (number | Point)[]> ['datasets'],
      scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry> }>
   }
}) => {

   return (
      <Box
         margin = { { top: '20px' } }
         flex
      >
         { loading
            ? <Loader />
            :  (options && Object.keys (options)?.length > 0)
               ? (
                  <Line
                     data    = { {
                        labels  : options.labels,
                        datasets: options.datasets
                     } }

                     options = { {
                        spanGaps           : true,
                        maintainAspectRatio: false,
                        responsive         : true,
                        interaction        : {
                           mode     : 'nearest',
                           axis     : 'xy',
                           intersect: true,
                        },
                        plugins: {
                           legend: {
                              //display : false
                              position: 'bottom',
                              labels  : {
                                 pointStyle   : 'circle',
                                 usePointStyle: true
                              }
                           },
                           zoom  : {
                              zoom: {
                                 wheel: { enabled: true },
                                 pinch: { enabled: true },
                                 mode : 'x',
                              },
                              pan: {
                                 enabled: true,
                                 mode: 'x',
                              },
                           }
                        },
                        scales: options.scales
                     } }
                  />
            ) : <Box>Нет данных для отображения</Box>
         }
      </Box>
   )
})