import React from "react"
import { Spinner } from "grommet"

export const Loader = ({ size }: { size?: string }) => {

   return (

      <div className = "loader-wrapper">
         <Spinner size = { `${size ?? "large"}` }/>
      </div>
   )
}
