import { makeAutoObservable } from "mobx"

import { auth, refresh } from "api/auth"
import { TAuthRequest  } from 'models/Auth'
import { reg, reset    } from 'api/user'
import { rootStore     } from "store/RootStore"

import {
   getLocalStorageItem,
   removeLocalStorageItem,
   setLocalStorageItem,
} from "utils/localStorage"

import { ACCESS_TOKEN, REFRESH_TOKEN } from "data/constants/globalConstants"
import { isOuter } from "data/common/routes"

export class AuthStore {

   loading = false;

   constructor () { makeAutoObservable (this) }

   async registration (data: TAuthRequest)
   {
      this.setLoading (true)

      return await reg (data)
      .then (result => result)
      .finally (() => this.setLoading (false))
   }

   async resetpassword (data: TAuthRequest)
   {
      this.setLoading (true)

      return await reset (data)
      .then (result => result)
      .finally (() => this.setLoading (false))
   }

   async login (data: TAuthRequest)
   {
      this.setLoading (true)

      return await auth (data)
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)
         setLocalStorageItem (REFRESH_TOKEN, result.refresh)

         if (getLocalStorageItem (ACCESS_TOKEN , null) &&
             getLocalStorageItem (REFRESH_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()
         }
      })
      .finally (() => this.setLoading (false))
   }

   async refresh ()
   {
      this.setLoading (true)

      return await refresh (getLocalStorageItem (REFRESH_TOKEN, null))
      .then (result => {

         setLocalStorageItem (ACCESS_TOKEN, result.token)
         setLocalStorageItem (REFRESH_TOKEN, result.refresh)

         if (getLocalStorageItem (ACCESS_TOKEN , null) &&
             getLocalStorageItem (REFRESH_TOKEN, null)) {
            rootStore.currentUserStore.getCurrentUserData ()
         }
      })
      .finally(() => {
         this.setLoading (false)
      })

   }

   logout ()
   {
      removeLocalStorageItem (ACCESS_TOKEN)
      removeLocalStorageItem (REFRESH_TOKEN)
      if (!isOuter (window.location.pathname)) window.location.replace ("/login")
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading;
   }
}
