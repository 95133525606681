import React, { useState } from 'react'

import {
   Box,
   Button,
   Form,
   FormField,
   TextInput,
} from 'grommet'
import { Key, Wifi      } from 'grommet-icons'
import { changePassword } from 'api/user'
import { toast          } from 'react-toastify'

export const ChangeWiFi = ({ onClose }: {onClose: () => void}) => {

   const [formValues, setFormValues] = useState ({
      currentPassword: '',
      newPassword    : '',
      confirmPassword: '',
   })

   const onSubmit = async () => {

      const result = await changePassword ({
         currentPassword: formValues.currentPassword,
         newPassword    : formValues.newPassword
      })

      if (!result?.error_text)
         toast.success ('Настройки WiFi успешно обновлены')

      onClose ()
   }

   return (

      <Box pad = { { horizontal: 'xxsmall' } }>
         <Form
            validate = "blur"
            value    = { formValues }
            onChange = { setFormValues }
            onSubmit = { onSubmit }
            method   = "post"
         >
            <FormField

               htmlFor  = "ssid"
               name     = "ssid"
               label    = "SSID"
               validate = { {
                  regexp: new RegExp('.+'),
                  message: 'Поле должно быть заполнено',
               } }
               validateOn = "submit"
            >
               <TextInput
                  reverse
                  icon        = { <Wifi /> }
                  id          = "ssid"
                  name        = "ssid"
                  placeholder = "Введите имя сети"
               />
            </FormField>

            <FormField

               htmlFor  = "password"
               name     = "password"
               label    = "Пароль"
            >
               <TextInput
                  reverse
                  icon        = { <Key /> }
                  id          = "password"
                  name        = "password"
                  placeholder = "Введите пароль сети"
                  type        = "password"
               />
            </FormField>

            <Box
               margin = { { 
                  top   : 'medium',
                  bottom: 'small' 
               } }
            >
               <Button
                  primary
                  fill
                  label = "Обновить настройки WiFi"
                  type  = "submit"
               />
            </Box>
         </Form>
      </Box>
   )
}