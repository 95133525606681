import React, { useContext } from 'react';

import { Box, FormField, ThemeContext } from 'grommet';
import { Phone } from 'grommet-icons';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'

export const InputPhone = ({
   phone,
   setPhone
}: {
   phone   :string,
   setPhone: React.Dispatch<React.SetStateAction<string>>
}) => {

   const theme = useContext (ThemeContext)

   return (
      <FormField
         label     = "Номер телефона"
         name      = "phone"
         className = "form__input"
         htmlFor   = "phone-sign-in"
         required  = { { indicator: false } }
         style     = { {
            position: 'relative',
            display : 'block'
         } }
      >
         <Box
            style = { {
               position: 'absolute',
               display : 'flex',
               top     : '40px',
               right   : '12px',
               pointerEvents: 'none'
            } }
         >
            <Phone />
         </Box>

         <PhoneInput
            dropdownStyle = { {
               // @ts-ignore
               color     : theme.global.colors.text.light
            } }
            buttonStyle = { {
               top       : '-3px',
               background: 'none',
               border    : 'none',
            } }
            inputStyle = { {
               // @ts-ignore
               color     : (theme.dark) ? theme.global.colors.text.dark : theme.global.colors.text.light,
               background: 'none',
               border    : 'none',
               height    : 'inherit',
               width     : 'inherit',
               padding   : '11px 48px',
               fontFamily: 'inherit',
               fontSize  : 'inherit',
            } }
            searchStyle = { {
               fontSize  : 'inherit',
               lineHeight: 'inherit',
               margin    : '0',
               padding   : '11px',
               width     : '98%'
            } }
            enableSearch
            disableSearchIcon
            searchPlaceholder = 'Поиск'
            searchNotFound    = 'Ничего не найдено'
            placeholder       = '0 (000) 000-0000'
            country           = { 'ru' }
            value             = { phone }
            onChange          = { phone => setPhone (phone) }
            inputProps = { {
               id       : 'phone-sign-in',
               name     : 'phone',
               required : true,
               autoFocus: true
            } }
         />
      </FormField>
   )
}