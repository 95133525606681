import React, { useEffect, useMemo, useState } from 'react'
import {
   MaskedInput,
   TableCell,
   TableRow
} from 'grommet'
import { useWaitCommand } from 'hooks/useWaitCommand'
import { ControlFabric } from 'components/Library/ControlFabric/ControlFabric'

export const SettingRow = ({
   set,
   setSetting,
   watchdog,
   ...prop
} : {
   set  : string | number,
   setSetting: (i, v) => void,
   watchdog: boolean,
   id   : string,
   alias: string,
   label: string,
   type?: "number" | "datetime-local" | "email",
   min? : number,
   max? : number,
   sign?: string,
}) => {

   const [value, setValue] = useState (set)

   const masks = useMemo (() => ({
      number: [{
         length: [1, 2],
         regexp: /^[0-9]{1,2}$/,
         placeholder: '00' + ` ${prop.sign}`
      }]
   }), [])

   useEffect (() => {
      /* watchdog
         ? controlCommand (prop.id, value)
         : */ setSetting (prop.id, value)
   }, [value])
      
   const { controlCommand, wait } = useWaitCommand (setSetting)

   return (
      <TableRow id = 'one'>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            { prop.label }
         </TableCell>

         <TableCell>
            <ControlFabric wait = { wait }>
               <MaskedInput
                  { ...prop }
                  value    = { value }
                  onChange = { e => setValue (Number (e.target.value)) }
                  mask     = { masks[prop.type] }
               />
            </ControlFabric>
         </TableCell>
      </TableRow>
   )
}