import React from "react";
import { TProjectItem } from "models/Project";

import { Card, CardFooter, CardHeader, CardBody, Text } from "grommet";
import { LinkButton } from "components/Library/components/LinkButton";
import { useNavigate } from "react-router-dom";

export function PanelCard ({ project }: { project: TProjectItem }) {

   const navigate = useNavigate ()

   return (
      <LinkButton
         onClick = { () => {
            navigate (`/${project.id_project}/${project.id_controlpanel}`)
            location.reload ()
         } }
      >
         <Card
            pad            = "medium"
            gap            = "medium"
            round          = "xsmall"
            background     = { (project.id_workspace === 2) ? "red" : "baseBackground" }
            onClick        = { () => false }
            hoverIndicator = { "backgroundCardHover" }
         >
            <CardHeader
               align   = "center"
               justify = "start"
            >
               <Text
                  size   = "medium"
                  weight = "bold"
               >
                  { project.cpname }
               </Text>
            </CardHeader>

            <CardBody
               align   = "start"
               justify = "start"
            >
               <Text
                  size   = "small"
                  weight = "normal"
               >
                  { project.name }
               </Text>
            </CardBody>

            <CardFooter
               align   = "center"
               justify = "start"
            >
               <Text
                  size   = "small"
                  weight = "normal"
               >
                  { new Date (project.dset).toLocaleString () }
               </Text>
            </CardFooter>

         </Card>
      </LinkButton>
   )
}
