import React, {
   CSSProperties,
   useEffect,
   useRef,
   useState
} from "react"

import { Box, Button, Text, TextInput } from "grommet"
import { Edit, Save } from "grommet-icons"

export const EditableBox = ({
   defaultValue,
   saveValue,
   style,
} : {
   defaultValue: number | string,
   saveValue   : (v?: unknown) => void,
   style?      : CSSProperties
}) => {

   const [editButton, setEditButton] = useState (false)
   const [editInput , setEditInput ] = useState (false)
   const [value     , setValue     ] = useState (defaultValue)

   const boxRef   = useRef (null)
   const inputRef = useRef (null)

   useEffect(() => {

      const closeClick = e => {

         if (editInput &&
             e.target !== inputRef.current &&
             e.target !== boxRef.current   &&
             e.target.tagName != 'path'    &&
             e.target.tagName != 'svg') {

            setEditInput (false)
            document.removeEventListener ("click", closeClick)
         }
      }

      const closeBtn = e => {

         if (e.key === 'Escape') {
            setEditInput (false)
            document.removeEventListener ("click", closeClick)
         }
         if (e.key === 'Enter') {
            saveValue (value)
            setEditInput (false)
            document.removeEventListener ("click", closeClick)
         }
      }

      if (editInput) {
         document.addEventListener ("keydown", closeBtn)
         document.addEventListener ("click"  , closeClick)
      }

      return () => {
         document.removeEventListener ("keydown", closeBtn)
         document.removeEventListener ("click"  , closeClick)
      }
   }, [editInput, value])

   return (
      <Box
         fill         = "horizontal"
         direction    = "row"
         style        = { {  position: 'relative', ...style } }
         onMouseEnter = { () => setEditButton (true)  }
         onMouseLeave = { () => setEditButton (false) }
         ref          = { boxRef }
      >
         { editInput 
           ? (
               <>
                  <TextInput 
                     defaultValue = { value }
                     onChange     = { e => setValue (e.target.value) }
                     ref          = { inputRef }
                  />
                  <Button
                     secondary
                     fill    = { false }
                     icon    = { <Save color = 'backgroundIcon'/> }
                     margin  = { { left: 'xsmall' } }
                     pad     = '0'
                     tip     = 'Enter'
                     onClick = { () => {
                        saveValue (value)
                        setEditInput (false)
                     } }
                  />
               </>
           )
           : (
               <>
                  <Text>
                     { defaultValue }
                  </Text>

                  { editButton &&
                     <Button
                        secondary
                        fill    = { false }
                        icon    = { <Edit color = 'backgroundIcon'/> }
                        style   = { { 
                           padding   : '0px',
                           position  : 'absolute',
                           right     : '5px',
                           zIndex    : '1000',
                        } }
                        onClick = { () => setEditInput (true) }
                     />
                  }
               </>
           )
         }
      </Box>
   )
} 