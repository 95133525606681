import { useEffect } from 'react'

const getLocaleDate = (date: Date): string => {

   return date.toLocaleString ('sv-SE', {
      year  : 'numeric',
      month : '2-digit',
      day   : '2-digit',
      hour  : '2-digit',
      minute: '2-digit'
   }).replace (" ", "T")
}

export const useDateRange = ({
   interval,
   setDBegin,
   setDEnd
} : {
   interval : number
   setDBegin: React.Dispatch<React.SetStateAction<string | string[]>>
   setDEnd  : React.Dispatch<React.SetStateAction<string | string[]>>
}) => {

   useEffect (() => {

      const now = new Date ()
      const end = new Date ()

      switch (interval) {
         case 1:
            end.setDate  (now.getDate  () - 1)
            break
         case 2:
            end.setDate  (now.getDate  () - 7)
            break
         case 3:
            end.setMonth (now.getMonth () - 1)
            break
         default:
            end.setDate  (now.getDate  () - 1)
            break
      }

      setDEnd   (getLocaleDate (now))
      setDBegin (getLocaleDate (end))
   }, [interval])
}

