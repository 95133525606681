import React, { FC } from 'react'

import { Outlet, Route, Routes } from 'react-router-dom'

import { ProjectsTable       } from 'components/Projects/ProjectsTable'
import { SettingComponent    } from 'components/User/Setting'
import { Page404             } from 'components/Common/Page404'
import { ControlPanel        } from 'components/ControlPanel'
import { PanelsTable         } from 'components/Panels/PanelsTable'
import { Login               } from 'components/Auth/Login'
import { Registration        } from 'components/Auth/Registration'
import { ResetPassword       } from 'components/Auth/ResetPassword'
import { NavigationList      } from "components/Navigation/NavigationList"

export const AllRoutesRender: FC = () => {

   return (

      <Routes>

         <Route
            path    = ''
            element = { // @ts-ignore
               <NavigationList>
                  <Outlet />
               </NavigationList> }
         >

            <Route
               path    = ':idProject'
               element = { <Outlet /> } 
            >
               <Route
                  index
                  element = { <PanelsTable /> } 
               />
               <Route
                  path    = ':idPanel'
                  element = { <Outlet /> } 
               >
                  <Route
                     index
                     element = { <ControlPanel /> } 
                  />
               </Route>
            </Route>

            <Route
               path = 'setting'
               element = { <SettingComponent /> } 
            />

            <Route
               path    = '/'
               element = { <ProjectsTable /> }
            />

         </Route>

         <Route
            path    = 'login'
            element = { <Login /> } 
         />

         <Route
            path    = 'registration'
            element = { <Registration /> } 
         />

         <Route
            path    = 'resetpassword'
            element = { <ResetPassword /> } 
         />

         <Route
            path    = '*'
            element = { <Page404 /> } 
         />

      </Routes>
   )
}
