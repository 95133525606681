import { api      } from 'api/client'
import { TSetting, TWifiList } from 'models/Setting'

export const getSettings = async (
   id_project: number
): Promise<TSetting[]> => {
   return await api.send ('GET', `/setting/?id_project=${id_project}`)
}

export const getUnitSettings = async (
   id_unit: number
): Promise<TSetting> => {
   return await api.send ('GET', `/setting/?id_unit=${id_unit}`)
}

export const setSettings = async (
   data: {
      id_unit : number,
      settings: TSetting
   }
): Promise<TSetting[]> => {
   return await api.send ("POST", '/setting/', data)
}

export const getUnitWifiList = async (): Promise<TWifiList> => {
   return await api.send ('GET', `/scan_wifi`)
}