import React, { useEffect, useState } from "react"
import { FormField, MaskedInput } from "grommet"

type TMask = {
   placeholder: string,
   length     : number[],
   regexp     : RegExp,
}

const maskTime: TMask[] = [
   {
      placeholder: 'Установите время',
      length     : [1, 2],
      regexp     : /^2[0,1-3]$|^1[0,1-9]$|^0?[1-9]$|^0$/,
   },
]

const maskValue: TMask[] = [
   {
      placeholder: 'Установите целевое значение',
      length     : [1, 2],
      regexp     : /^[1-9][0,1-9]$|^0?[1-9]$|^0$/,
   },
]

export const DecimalInput = ({
   title,
   name,
   type,
   value,
   setValue,
}: {
   title   : string,
   name    : string,
   type    : 'time' | 'temperature',
   value   : number,
   setValue: React.Dispatch<React.SetStateAction<number>>
}) => {

   const [mask, setMask] = useState <TMask[]> ()

   useEffect (() => {
      switch (type) {
         case 'time':
            setMask (maskTime)
            break
   
         case 'temperature':
            setMask (maskValue)
            break

         default: break
      }
   }, [])

   return (
      <FormField
         label   = { title }
         htmlFor = { name }
         name    = { name }
         required
      >
         <MaskedInput
            name     = { name }
            id       = { name }
            mask     = { mask }
            value    = { value }
            onChange = { e => setValue (Number (e.target.value)) }
            type     = "number"
         />
      </FormField>
   )
}