import React, { useEffect, useRef, useState } from 'react'

import { TCurrentUser   } from 'models/CurrentUser'
import { rootStore      } from 'store/RootStore'
import { editUser       } from 'api/user'

import {
   Table,
   Button,
   Card,
   CardBody,
   CardHeader,
   Heading,
   TableBody,
   TableCell,
   TableRow,
} from 'grommet'
import { ChangePassword } from "components/Auth/ChangePassword"
import { EditableBox    } from 'components/Library/components/EditableBox'
import { Modal          } from 'components/Library/Modal/Modal'
import { toast          } from 'react-toastify'

export const UserSettingCard = ({
   title,
   data,
} : {
   title: string,
   data: TCurrentUser,
}) => {

   const [edit , setEdit ] = useState (false)
   const [name , setName ] = useState (data?.name)
   const [email, setEmail] = useState (data?.email)
   const [phone, setPhone] = useState (data?.phone)
   const isNotFirst = useRef (false)

   useEffect (() => {

      const saveUser = async () => {

         const result = await editUser ({
            name,
            email,
            phone
         })
   
         if (!result?.error_text) {
            toast.success ('Данные пользователя успешно обновлёны')
            rootStore.currentUserStore.setUser (result)
         }
   
         setEdit (false)
      }

      if (isNotFirst.current && (name || email || phone)) {
         saveUser ()
      }

      isNotFirst.current = true
   }, [name, email, phone])

   //console.log (isNotFirst, name, email, phone)

   return (
      <>
         <Card
            elevation = 'large'
            pad       = "medium"
         >
            <CardHeader
               style  = { {
                  borderBottom: "1px solid #dfe4ec"
               } }
            >
               <Heading
                  margin = { {
                     horizontal: 'small',
                     top       : 'none'
                  } }
                  level  = '3'
               >
                  { title }
               </Heading>
            </CardHeader>

            <CardBody pad = { { top: 'medium' } } >
               <Table style = { { display: 'flex' } } >
                  <TableBody>

                     { name && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Имя:</strong>
                           </TableCell>
                           <TableCell>
                              <EditableBox
                                 defaultValue = { name }
                                 saveValue    = { setName }
                              />
                           </TableCell>
                        </TableRow>
                     ) }

                     { email && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Еmail:</strong>
                           </TableCell>
                           <TableCell>
                              <EditableBox
                                 defaultValue = { email }
                                 saveValue    = { setEmail }
                              />
                           </TableCell>
                        </TableRow>
                     ) }

                     { phone && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Телефон:</strong>
                           </TableCell>
                           <TableCell>
                              <EditableBox
                                 defaultValue = { phone }
                                 saveValue    = { setPhone }
                              />
                           </TableCell>
                        </TableRow>
                     ) }

                     { data.dset && (
                        <TableRow>
                           <TableCell
                              scope = "row"
                              size  = 'xsmall'
                           >
                              <strong>Дата создания:</strong>
                           </TableCell>
                           <TableCell> { new Date (data.dset).toLocaleString () }</TableCell>
                        </TableRow>
                     ) }

                     <TableRow>
                        <TableCell
                           scope = "row"
                           size  = 'xsmall'
                        >
                           <strong>Пароль:</strong>
                        </TableCell>

                        <TableCell align = 'right' >
                           <Button
                              label   = "Изменить"
                              onClick = { () => setEdit (true) }
                           />
                        </TableCell>
                     </TableRow>

                  </TableBody>
               </Table>
            </CardBody>
         </Card>

         <Modal
            modal    = { edit }
            setModal = { setEdit }
            heading  = 'Изменить пароль'
            width    = '450px'
            height   = 'auto'
         >
            <ChangePassword onClose = { () => setEdit (false) } />
         </Modal>
      </>
   )
}
