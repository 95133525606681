import React, { useMemo, useState } from 'react'
import {
   MaskedInput,
   TableCell,
   TableRow
} from 'grommet'

export const SettingRow = ({
   defaultValue,
   ...setting
} : {
   defaultValue: string,
   id   : string,
   alias: string,
   label: string,
   type?: "number" | "datetime-local" | "email",
   min? : number,
   max? : number,
   sign?: string,
}) => {

   const [value, setValue] = useState (defaultValue)

   const masks = useMemo (() => ({
      number: [{
         length: [1, 2],
         regexp: /^[0-9]{1,2}$/,
         placeholder: '00' + ` ${setting.sign}`
      }]
   }), [])

   return (
      <TableRow id = 'one'>
         <TableCell
            scope  = 'row'
            border = { {
               side: 'right',
               size: 'small'
            } }
         >
            { setting.label }
         </TableCell>

         <TableCell>
            <MaskedInput
               { ...setting }
               value    = { value }
               onChange = { e => setValue (e.target.value) }
               mask     = { masks[setting.type] }
            />
         </TableCell>
      </TableRow>
   )
}