import React from 'react'
import { observer } from 'mobx-react'

import { TIndicationBlock     } from 'models/ControlPanel'
import { Box, Heading, Text   } from 'grommet'

export const DummyTagBlock = observer (( { block } : { block: TIndicationBlock }) => {

   return (

      <Box
         round      = "small"
         pad        = "xsmall"
         background = "brand"
         flex       = 'grow'
      >
         <Heading
            level     = { 3 }
            textAlign = "center"
            margin    = { { top: 'xsmall', bottom: 'none' } }
            weight    = 'lighter' 
            fill
         >
            { block.label ?? block.id }
         </Heading>

         <Box fill>
            <Text
               weight    = "bold"
               textAlign = "center"
               size      = "2xl"
               margin    = { { vertical: "xsmall" } }
            >
                  13 %
            </Text>
         </Box>
      </Box>
   )
})