import React from 'react'
import { observer     } from 'mobx-react'
import { useParams    } from 'react-router-dom'

//import { rootStore } from "store/RootStore"
import { projectStore } from 'store/ProjectsStore'

import {
   //Box,
   PageHeader
} from 'grommet'

export const Greeting = observer (() => {

   const { idProject, idPanel } = useParams ()

   return (
      <PageHeader
         title    = { projectStore.projects.find (project => project.id_project === Number (idProject) &&
                                                             project.id_controlpanel === Number (idPanel))?.cpname }
         //subtitle = { <Box> { `Здравствуйте, ${ rootStore.currentUserStore.currentUserData?.name ?? "" }! 
         //                      Добро пожаловать в контрольную панель управления проектом.` } </Box> }
         pad      = { { bottom: "large" } }
         fill     = 'horizontal'
         responsive
      />
   )
})