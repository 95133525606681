import { makeAutoObservable } from "mobx";
import { errorHandler } from "utils/errorHandler";

import { TSetting, TTask } from "models/Setting";
import { getSettings } from "api/setting";

class SettingsStore {

   loading = false
   settings: TSetting[] = []
   idProject: number

   constructor () { makeAutoObservable (this) }

   async getSettings (idProject: number) {

      this.setLoading (true)

      try {
         if (this.idProject != idProject) {
            this.idProject = idProject
            this.settings = []
         }
         
         getSettings (idProject)
         .then (result => this.setSettings (result))
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   setSettings (setting: TSetting[]) {

      this.settings = setting
      /* const idx = this.settings.flatMap (unitSetting => unitSetting.id_unit).indexOf (setting.id_unit)

      if (idx === -1) this.settings = [...this.settings, ...setting]
      else            this.settings.splice (idx, 1, setting)
 */
      this.setLoading (false)
   }

   setLoading = (isLoading: boolean) => {
      this.loading = isLoading
   };

   get schedules (): TTask[] {
      if (this.settings?.length > 0) return this.settings?.flatMap (unitSetting => unitSetting.settings?.Schedule)
   }
}

export const settingsStore = new SettingsStore ()
