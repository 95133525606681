import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { SettingsOption } from 'grommet-icons'

import {
   Box,
   Button,
   Table,
   TableBody,
} from 'grommet'
import { Modal } from 'components/Library/Modal/Modal'
import { SettingRow } from 'ui/table/SettingRow'
import { controlPanelStore } from 'store/ControlPanelStore'

export const Setting = observer (() => {

   const [modal, setModal] = useState (true)

   return (
      <>
         <Button
            primary
            label   = 'Настройка'
            icon    = { <SettingsOption /> }
            fill    = 'horizontal'
            onClick = { () => setModal (true) }
         />

         <Modal
            modal    = { modal }
            setModal = { setModal }
            heading  = 'Общие настройки'
         >
            <Box>
               <Table>
                  <TableBody>

                     { controlPanelStore.baseSetting.map (setting => 
                        <SettingRow
                           key = { setting.id }
                           defaultValue = '20'
                           { ...setting }
                        />
                     ) }

                  </TableBody>
               </Table>
            </Box>
         </Modal>

      </>
   )
})