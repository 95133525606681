import { useCallback, useState } from 'react'
import { getTagChart        } from 'api/tagchart'
//import { processChartData   } from './processChartData'
import { _DeepPartialObject } from 'chart.js/dist/types/utils'
import { TTag               } from 'models/Tag'

import {
   TCustomBlock,
   TIndicationBlock,
} from 'models/ControlPanel'

import {
   ChartData,
   Point,
   ScaleOptionsByType,
   CartesianScaleTypeRegistry,
} from 'chart.js'
import { devicesStore } from 'store/DevicesStore'
import { ChartProcessor } from './chartProcessor'

export const useChartData = (
   tag      : TTag,
   block    : TIndicationBlock | TCustomBlock,
   interval : number,
   dBegin   : string,
   dEnd     : string,
   fullData : boolean,
   groupData: boolean
) => {

   const [loading, setLoading] = useState (true)
   const [options, setOptions] = useState <{
      labels  : ChartData['labels'],
      datasets: ChartData <'line', (number | Point)[]> ['datasets'],
      scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry> }>
   }> ()

   const fetchChartData = useCallback (() => {

      setLoading (true)
      let tags = [tag?.id_tag]
      let rifts: TTag['id_tag'][] = []

      if (block?.charts) {
         tags = tags.concat (block?.charts
         .filter (({ type }) => type != 'rift')
         .flatMap (chart => chart.type == 'diff' ? chart.tags : chart.id)
         .map (id => devicesStore.tags.find (tag => tag.id == id && tag.alias == block.alias)?.id_tag))

         rifts = rifts.concat (block?.charts
         .filter (({ type }) => type == 'rift')
         .flatMap (({ id }) => id)
         .map (id => devicesStore.tags.find (tag => tag.id == id && tag.alias == block.alias)?.id_tag))
      }

      getTagChart (tags, dBegin, dEnd, fullData, groupData)
      .then (tagSamples => {

         if (rifts.length > 0) {

            getTagChart (rifts, dBegin, dEnd, fullData, groupData, true)
            .then (riftSamples => {

               const samples = tagSamples.concat (riftSamples)
               //const chartData = processChartData (tag, block, samples, interval, fullData)
               const chartProcessor = new ChartProcessor (tag, block, samples, interval, fullData)
               setOptions (chartProcessor.processChartData ())
            })
         }
         else {

            //const chartData = processChartData (tag, block, tagSamples, interval, fullData)
            const chartProcessor = new ChartProcessor (tag, block, tagSamples, interval, fullData)
            setOptions (chartProcessor.processChartData ())
         }
      })
      .finally (() => setLoading (false))

   }, [tag, dBegin, dEnd, fullData, groupData])

   return { options, loading, fetchChartData }
}
