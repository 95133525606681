import { makeAutoObservable } from "mobx"
import { rootStore          } from "./RootStore"

import { 
   getControllerState,
   queryControllerState,
   setControllerCommand
} from "api/controller"

import {
   TControllerState,
   TStateRequestResult,
   TTarget
} from "models/ControllerState"

import { toast             } from "react-toastify"
import { errorHandler      } from "utils/errorHandler"
import { STATE_TIMER       } from "data/constants/globalConstants"
import { controlPanelStore } from "./ControlPanelStore"

class ControllerStateStore {

   loading  = false
   idProject: number
   location : string
   state    : TControllerState[] = []
   intervals: {
      [k: string]: ReturnType <typeof setTimeout>
   } = {}

   constructor () { makeAutoObservable (this) }

   getState (idProject: number)
   {
      this.setLoading (true)

      if (this.idProject != idProject) {
         this.idProject = idProject
         this.clearIntervals ()
      }

      if (controlPanelStore.aliases.length > 0) {
         controlPanelStore.aliases.forEach (alias => {
            if (this.idProject && alias) this.getStateCommand (this.idProject, alias)
         })
      } else this.setLoading (false)
   }

   setState (controllerState: TStateRequestResult) {

      switch (controllerState?.code) {
         case 0: {

            const stat = controllerState.data
            .find (ctrl => this.state
            .find (stat => stat?.name &&
                           stat.name  === ctrl.name &&
                           stat.value !== ctrl.value))

            if (this.state.length === 0 || stat)
               this.state = controllerState.data
            break
         }

         case 7:
         case 102:
            break

         default:
            errorHandler (controllerState.message)
            this.clearIntervals ()
            break
      }

      if (Object.keys (this.intervals).length == 0) {

         controlPanelStore.aliases.forEach (alias => {

            if (alias)
               this.intervals[alias] = setInterval (async () => {

                  if (rootStore.currentUserStore.currentUserData &&
                      this.idProject != null) this.queryStateCommand (this.idProject, alias)
                  else                        this.clearIntervals ()
               }, (STATE_TIMER))
         })
      }

      this.setLoading (false)
   }

   async getStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await getControllerState (idProject, alias)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async queryStateCommand (idProject: number, alias: string)
   {
      try {
         const state = await queryControllerState (idProject, alias)
         if (state) this.setState (state)
      }
      catch (err) {
         if (!err.error_text) errorHandler (err)
         this.setLoading (false)
      }
   }

   async setCommand (
      project: number,
      alias  : string,
      command: number,
      value? : number | TTarget,
   ) {

      const result = await setControllerCommand (
         project,
         alias,
         command, 
         value
      )
      
      if (result.Code == 0) toast.success (result.Message)
      else                  toast.error   (result.Message)
   }

   setLoading (isLoading: boolean)
   {
      this.loading = isLoading
   }

   setLocation (location: string)
   {
      this.location = location
   }

   clearIntervals () {
      Object.values (this.intervals)
      .forEach (interval => clearInterval (interval))
      this.state = []
   }
}

export const controllerStateStore = new ControllerStateStore ()