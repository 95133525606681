import React, { useState } from "react"
import { observer        } from "mobx-react"

import { rootStore       } from "store/RootStore"
import { TAuthRequest    } from "models/Auth"

import { Key             } from "grommet-icons"
import { HeaderComponent } from "components/Common/Header"
import { InputPhone      } from "./components/InputPhone"
import {
   Form,
   FormField,
   Anchor,
   Button,
   Box,
   TextInput,
   CheckBox,
   Heading
} from "grommet"

//import { maskPhone, phoneValidation } from './utils/FormValidation';

export const Login = observer (() => {

   const [phone   , setPhone   ] = useState ('')
   const [password, setPassword] = useState ('')
   const [remember, setRemember] = useState (true)

   const submitLoginForm = async (data: TAuthRequest) =>
      rootStore.authStore.login (data)

   return (

      <div className = "main-layout">

         <HeaderComponent />
         <>
            <Box
               fill
               align   = "center"
               justify = "center"
               margin  = { { top: "large" } }
            >

               <Heading
                  level  = { 2 }
                  margin = "none"
               >
                  Авторизация
               </Heading>

               <Box
                  width  = "medium"
                  margin = "large"
                  pad    = "small"
               >
                  <Form
                     validate  = "blur"
                     name      = "basic"
                     className = "form"
                     messages  = { { required: 'Это поле должно быть заполнено' } }
                     value     = { {
                        phone   : phone,
                        password: password,
                        remember: remember
                     } }
                     onChange  = { values => {
                        setPhone    (values.phone)
                        setPassword (values.password)
                        setRemember (values.remember)
                     } }
                     onSubmit  = { e =>
                        submitLoginForm (e.value as TAuthRequest) }
                  >

                     <InputPhone
                        phone    = { phone }
                        setPhone = { setPhone }
                     />

                     <FormField
                        label     = "Пароль"
                        name      = "password"
                        className = "form__input"
                        required  = { { indicator: false } }
                        htmlFor   = "password-sign-in"
                     >
                        <TextInput
                           reverse
                           id   = "password-sign-in"
                           name = "password"
                           type = "password"
                           placeholder = "Введите пароль"
                           icon = { <Key /> }
                        />
                     </FormField>

                     <Box
                        direction = "row"
                        justify   = "between"
                        margin    = { { top: "small", left: "small" } }
                     >
                        <CheckBox
                           id       = "remember-me"
                           name     = "remember"
                           label    = "Запомните меня"
                           checked  = { remember }
                        />
                     </Box>

                     <Box
                        direction = "row"
                        justify   = "between"
                        margin    = { { vertical: "medium" } }
                     >
                        <Button
                           primary
                           fill
                           label   = "Войти"
                           type    = "submit"
                           justify = "center"
                        />
                     </Box>

                  </Form>

                  <Box
                     direction = "row"
                     justify   = "between"
                  >
                     <Box
                        align  = "center"
                        margin = { { top: 'small' } }
                     >
                        <Anchor
                           label = "Не помню пароль"
                           href  = "/resetpassword"
                        />
                     </Box>

                     <Box
                        align  = "center"
                        margin = { { top: 'small' } }
                     >
                        <Anchor
                           label = "Регистрация"
                           href  = "/registration"
                        />
                     </Box>
                  </Box>
               </Box>
            </Box>
         </>
      </div>
   )
})
