import React from "react"

import { TTarget } from "models/ControllerState"
import { Box, Button, CardBody, CardFooter, FormField, Heading, MaskedInput } from "grommet"

export const TargetScheduleValue = ({
   targetValue,
   setTargetValue,
   setEdit,
   sendCommand,
}: {
   targetValue   : TTarget,
   setTargetValue: React.Dispatch<React.SetStateAction<TTarget>>
   setEdit       : (boolean) => void
   sendCommand   : () => void,
}) => {

   const maskTime = [
      {
         placeholder: 'Установите время',
         length     : [1, 2],
         regexp     : /^2[0,1-3]$|^1[0,1-9]$|^0?[1-9]$|^0$/,
      },
   ]

   const maskValue = [
      {
         placeholder: 'Установите целевую температуру',
         length     : [1, 2],
         regexp     : /^[1-9][0,1-9]$|^0?[1-9]$|^0$/,
      },
   ]

   return (

      <>
         <CardBody
            margin    = { { vertical: "xsmall" } }
            direction = "row"
            style     = { { gap: '24px' } }
         >
            <Box fill>
               <Heading level = { 3 }>
                  Интервал 1
               </Heading>

               <FormField
                  label   = "Время начала"
                  htmlFor = "time1"
                  name    = "time1"
                  required
               >
                  <MaskedInput
                     name     = "time1"
                     id       = "time1"
                     mask     = { maskTime }
                     value    = { targetValue?.time1 }
                     onChange = { e => setTargetValue (prev => ( { ...prev, time1: Number (e.target.value) }) ) }
                     type     = "number"
                  />
               </FormField>

               <FormField
                  label   = "Целевая температура"
                  htmlFor = "value1"
                  name    = "value1"
                  required
               >
                  <MaskedInput
                     name     = "value1"
                     id       = "value1"
                     mask     = { maskValue }
                     value    = { targetValue?.value1 }
                     onChange = { e => setTargetValue (prev => ( { ...prev, value1: Number (e.target.value) }) ) }
                     type     = "number"
                  />
               </FormField>
            </Box>

            <Box fill>
               <Heading level = { 3 }>
                  Интервал 2
               </Heading>

               <FormField
                  label   = "Время начала"
                  htmlFor = "time2"
                  name    = "time2"
                  required
               >
                  <MaskedInput
                     name     = "time2"
                     id       = "time2"
                     mask     = { maskTime }
                     value    = { targetValue?.time2 }
                     onChange = { e => setTargetValue (prev => ( { ...prev, time2: Number (e.target.value) }) ) }
                     type     = "number"
                  />
               </FormField>

               <FormField
                  label   = "Целевая температура"
                  htmlFor = "value2"
                  name    = "value2"
                  required
               >
                  <MaskedInput
                     name     = "value2"
                     id       = "value2"
                     mask     = { maskValue }
                     value    = { targetValue?.value2 }
                     onChange = { e => setTargetValue (prev => ( { ...prev, value2: Number (e.target.value) }) ) }
                     type     = "number"
                  />
               </FormField>
            </Box>
         </CardBody>
         
         <CardFooter justify = 'between'>
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />
            <Button
               primary
               label   = 'Применить'
               margin  = { { top: 'small' } }
               onClick = { () => sendCommand () }
            />
         </CardFooter>
      </>
   )
}