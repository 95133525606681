import React, { useState, useEffect } from 'react'
import { controllerStateStore } from 'store/ControllerStateStore'
import { TCustomBlock         } from 'models/ControlPanel'
import { observer             } from 'mobx-react'

import {
   Card,
   CardHeader,
   Heading,
} from 'grommet'

import { TargetView } from './components/TargetView'
import { TargetEdit } from './components/TargetEdit'


export const TargetBlock = observer (({ block }: { block: TCustomBlock }) => {

   const [edit       , setEdit       ] = useState <boolean> (false)
   const [targetValue, setTargetValue] = useState <number>  ()

   useEffect (() => {

      setTargetValue (
         controllerStateStore.state
         .find (stat => stat.name == block.id)?.value
      )
   }, [controllerStateStore.state, block])

   return (

      <Card
         pad    = "medium"
         border = { { color: 'brand', size: 'xsmall' } }
         flex   = 'grow'
      >
         <CardHeader justify = "center">
            <Heading
               level  = { 3 }
               margin = { { top: 'xxsmall', bottom: 'none' } }
            >
               { block.label }
            </Heading>
         </CardHeader>

         { !edit &&
            <TargetView
               block             = { block }
               targetValue       = { targetValue }
               setEdit           = { setEdit }
            />
         }

         { edit &&
            <TargetEdit
               block            = { block }
               targetValue      = { targetValue }
               setTargetValue   = { setTargetValue }
               setEdit          = { setEdit }
            />
         }
      </Card>
   )
})