import React, { useEffect } from "react"
import { observer } from "mobx-react"

import { projectStore } from "store/ProjectsStore"
import { controllerStateStore } from "store/ControllerStateStore"

import { Box, Text, Grid, Heading } from "grommet"
import { Loader    } from "components/Common/Loader"
import { PanelCard } from "./components/PanelCard"
import { Package   } from "grommet-icons"
import { useParams } from "react-router-dom"

export const PanelsTable = observer (() => {

   const { idProject } = useParams ()
   useEffect (() => { controllerStateStore.idProject = null }, [])

   if (projectStore.loading) return <Loader />
   else {

      return projectStore.projects ? (

         <Box pad = "large">
            <Box
               direction = "row"
               align     = "start"
               gap       = "small"
               className = "product-table-nav"
            >
               <Heading style = { { margin: "0" } }>
                  Контрольные панели
               </Heading>
            </Box>

            <Grid
               columns   = "medium"
               gap       = "small"
               className = { "main" }
            >
               { projectStore.projects
               .filter (project => project.id_project == Number (idProject))
               .map    (project => 

                  <PanelCard
                     project = { project }
                     key     = { project.id_controlpanel }
                  />
               ) }
            </Grid>
         </Box>

      ) : (

         <Box
            align   = "center"
            justify = "center"
            margin  = "xlarge"
         >
            <Package />
            <Text>Нет проектов</Text>
         </Box>
      );
   }
});
