import React, { useCallback, useContext } from 'react'
import { observer  } from 'mobx-react'
import { useParams } from 'react-router-dom'

import { controllerStateStore } from 'store/ControllerStateStore'
import { TControlBlock        } from 'models/ControlPanel'

import { ControlButton        } from 'components/Library/Controls/ControlButton'
import { ControlRangeInput    } from 'components/Library/Controls/ControlRangeInput'
import { ControlToggle        } from 'components/Library/Controls/ControlToggle'
import { ControlRadioGroup    } from 'components/Library/Controls/ControlRadioGroup'
import { ControlText          } from 'components/Library/Controls/ControlText'
import { ControlButtonsGroup  } from 'components/Library/Controls/ControlButtonsGroup'
import { ControlSelect        } from 'components/Library/Controls/ControlSelect'
import {
   Box,
   Card,
   CardBody,
   CardHeader,
   Heading,
   ResponsiveContext
} from 'grommet'

export const ControlBlock = observer (({ block } : { block: TControlBlock }) => {

   const { idProject } = useParams ()
   const size = useContext (ResponsiveContext)

   const sendCommand = useCallback ( async (
      alias  : string,
      command: number,
      value  : number
   ) => controllerStateStore.setCommand (Number (idProject), alias, command, value),
   [block.alias, idProject])

   return (

      <Card
         border    = { block?.label ? { color: 'brand', size: 'xsmall' } : false }
         pad       = { { horizontal: "medium" } }
         flex      = 'grow'
         width     = { size == 'large' ? 'large' : 'medium' }
         style     = { block.style }
         className = { block.class }
         //@ts-ignore
         background = { block?.style?.background }
      >
         { block &&
            <CardHeader
               direction = { block.controls
               .some (control => control.type == 'RadioGroup') && size == 'small' ? 'column' : 'row' }
            >
               <Heading 
                  level  = { 3 }
                  style = { { whiteSpace: 'nowrap' } }
               >
                  { block?.label }
               </Heading>

               { (block && controllerStateStore.state?.length > 0) &&

                  <>
                     { block.controls
                     .filter (control => control.type == 'Toogle')
                     .map    (control =>

                        <ControlToggle
                           key         = { control.id }
                           alias       = { block.alias }
                           control     = { control }
                           watchdog    = { control.watchdog ?? true }
                           sendCommand = { sendCommand }
                        />
                     ) }

                     { block.controls
                     .filter (control => control.type == 'Select')
                     .map    (control =>

                        <ControlSelect
                           key         = { control.id }
                           alias       = { block.alias }
                           control     = { control }
                           watchdog    = { control.watchdog ?? true }
                           sendCommand = { sendCommand }
                        />
                     ) }

                     { block.controls
                     .filter (control => control.type == 'RadioGroup')
                     .map    (control => 

                        <Box key = { control.id } >
                           <ControlRadioGroup
                              alias       = { block.alias }
                              control     = { control }
                              watchdog    = { control.watchdog ?? true }
                              sendCommand = { sendCommand }
                           />
                           { size == 'small' && <Box pad = '10px' /> }
                        </Box>
                     ) }
                  </>
               }
            </CardHeader>
         }

         <CardBody>

            { (block.controls.some (control => control.type != 'Toogle'      &&
                                               control.type != 'RadioGroup') &&
                                     controllerStateStore.state?.length > 0) &&

               <Box
                  pad       = { { bottom: "medium" } }
                  direction = "column"
                  fill
               >
                  { block.controls
                  .filter (control => control.type == 'RangeInput')
                  .map    (control =>
                     <ControlRangeInput
                        key         = { control.id }
                        alias       = { block.alias }
                        control     = { control }
                        watchdog    = { control.watchdog ?? true }
                        sendCommand = { sendCommand }
                     />
                  ) }

                  { block.controls.filter (controlo => controlo.type != 'Toogle' &&
                                                       controlo.type != 'RadioGroup').length > 0 &&
                     <Box pad = { { vertical: "xsmall" } } />
                  }

                  { block.controls
                  .filter (control => control.type == 'Button')
                  .map    (control =>
                     <ControlButton
                        key         = { control.id }
                        alias       = { block.alias }
                        control     = { control }
                        sendCommand = { sendCommand }
                        watchdog    = { control.watchdog ?? true }
                     />
                  ) }

                  { block.controls
                  .filter (control => control.type == 'ButtonGroup')
                  .map    (control =>
                     <ControlButtonsGroup
                        key         = { control.id }
                        alias       = { block.alias }
                        control     = { control }
                        sendCommand = { sendCommand }
                        watchdog    = { control.watchdog ?? true }
                     />
                  ) }

                  { block.controls
                  .filter (control => control.type == 'Text')
                  .map    (control =>
                     <ControlText
                        key     = { control.id }
                        control = { control }
                     />
                  ) }
               </Box>
            }

         </CardBody>
      </Card>
   )
})