import React, { useEffect, useState } from "react"
import { observer } from "mobx-react"

import { projectStore } from "store/ProjectsStore"
import { sampleStore  } from "store/SampleStore"
import { controllerStateStore } from "store/ControllerStateStore"

import { Loader       } from "components/Common/Loader"
import { ProjectCard  } from "./components/ProjectCard"
import { Package      } from "grommet-icons"
import { Box, Text, Grid, Heading } from "grommet"

export const ProjectsTable = observer (() => {

   const [projects, setProjects] = useState ([])

   useEffect (() => {
      controllerStateStore.idProject = null
      sampleStore.idProject = null
   }, [])

   useEffect (() => {
      if (projectStore.projects.length > 0)
         setProjects ([...new Map (projectStore.projects
         .map (item => [item['id_project'], item])).values()])
   }, [projectStore.projects])

   if (projectStore.loading) return <Loader />
   else {

      return projects.length > 0
      ? (

         <Box pad = "large">
            <Box
               direction = "row"
               align     = "start"
               gap       = "small"
               className = "product-table-nav"
            >
               <Heading style = { { margin: "0" } }>
                  Проекты
               </Heading>
            </Box>

            <Grid
               columns   = "medium"
               gap       = "small"
               className = { "main" }
            >
               { projects.map (project =>
                  <ProjectCard
                     key     = { project.id_project }
                     project = { project }
                  />
               ) }

            </Grid>
         </Box>

      ) : (

         <Box
            align   = "center"
            justify = "center"
            margin  = "xlarge"
         >
            <Package />
            <Text>Нет проектов</Text>
         </Box>
      )
   }
})
