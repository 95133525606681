import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { observer  } from "mobx-react"

import { controllerStateStore } from "store/ControllerStateStore"
import { TCustomBlock         } from "models/ControlPanel"

import { Button, CardBody, CardFooter } from "grommet"

import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { useWaitCommand       } from "hooks/useWaitCommand"
import { DecimalInput } from "components/Library/components/DecimalInput"

export const TargetEdit = observer (({
   block,
   targetValue,
   setTargetValue,
   setEdit
}: {
   block         : TCustomBlock,
   targetValue   : number,
   setTargetValue: React.Dispatch<React.SetStateAction<number>>
   setEdit       : (boolean) => void
}) => {

   const { idProject } = useParams ()
   const [valueView, setValueView] = useState <number> (block?.look ? eval (block?.look) (targetValue) : targetValue)

   useEffect (() => {
      setTargetValue (block?.muta ? eval (block?.muta) (valueView) : valueView)
   }, [valueView])

   const command = useMemo (() => 
      block.controls.flatMap (control => control.options)[0].command,
   [block.controls])

   const sendCommand = useCallback (async (
      alias: string,
      command: number,
      value: number
   ) => {
      controllerStateStore.setCommand (
         Number (idProject),
         block.alias,
         command,
         value
      )
      setEdit (false)
   }, [block.alias, idProject])

   const { controlCommand, wait } = useWaitCommand (sendCommand)

   return (
      <>
         <CardBody margin = { { vertical: "xsmall" } } >
            <DecimalInput
               title    = 'Целевое значение'
               name     = { block.id }
               type     = 'temperature'
               value    = { valueView }
               setValue = { setValueView }
            />
         </CardBody>

         <CardFooter justify = 'between' >
            <Button
               secondary
               label   = 'Отмена'
               margin  = { { top: 'small' } }
               onClick = { () => setEdit (false) }
            />

            <ControlFabric wait = { wait } >
               <Button
                  primary
                  label     = 'Применить'
                  margin    = { { top: 'small' } }
                  onClick   = { () => controlCommand (block.alias, command, targetValue) }
               />
            </ControlFabric>

         </CardFooter>
      </>
   )
})