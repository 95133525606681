import React, { createContext, useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { useDateRange } from './hooks/useDateRange'
import { useChartData } from './hooks/useChartData'
import { TTag         } from 'models/Tag'

import { Box           } from 'grommet'
import { ChartControls } from './components/ChartControls'
import { ChartDisplay  } from './components/ChartDisplay'
import { TCustomBlock, TIndicationBlock } from 'models/ControlPanel'

export const ChartContext = createContext (null)

export const TagChart = observer (({ tag, block }: { 
   tag  : TTag,
   block: TIndicationBlock | TCustomBlock
}) => {

   const [dBegin, setDBegin] = useState <string> ()
   const [dEnd  , setDEnd  ] = useState <string> ()

   const [interval , setInterval ] = useState <1 | 2 | 3 | 4> (1)
   const [fullData , setFullData ] = useState (false)
   const [groupData, setGroupData] = useState (true)

   const chartContext = {
      dBegin   , setDBegin,
      dEnd     , setDEnd,
      interval , setInterval,
      fullData , setFullData,
      groupData, setGroupData,
   }

   useDateRange ({ interval, setDBegin, setDEnd })
   const { options, loading, fetchChartData } = useChartData (
      tag,
      block,
      interval,
      dBegin,
      dEnd,
      fullData,
      groupData
   )

   useEffect(() => {
      if (dBegin && dEnd) fetchChartData ()
   }, [dBegin, dEnd, fullData, groupData])

   return (
      <Box
         direction = 'column'
         justify   = 'between'
         fill
      >
         <ChartContext.Provider value = { chartContext }>
            <ChartControls
               tag            = { tag }
               block          = { block }
               fetchChartData = { fetchChartData }
            />
         </ChartContext.Provider>
         <ChartDisplay
            options = { options }
            loading = { loading }
         />
      </Box>
   )
})