import React from "react";

import { controllerStateStore } from "store/ControllerStateStore";

import { Box } from "grommet";
import { ThemeSwitchButton } from "components/Navigation/ThemeSwitch/ThemeSwitchButton";

controllerStateStore.setLocation (String (window.location))

export function HeaderComponent () {

   return (
      <header>
         <Box
            direction = "row"
            align     = "center"
            justify   = "center"
         >
            <ThemeSwitchButton />
         </Box>
      </header>
   );
}
