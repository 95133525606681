import { devicesStore } from "store/DevicesStore"

import { TChartSample } from "models/Sample"
import { TTag } from "models/Tag"
import {
   TCustomBlock,
   TIndicationBlock,
   TDiffChart,
   TRiftChart,
   TTagChart,
} from "models/ControlPanel"
import { Point } from "chart.js/dist/core/core.controller"
import { _DeepPartialObject } from "chart.js/dist/types/utils"

import { CartesianScaleTypeRegistry, ChartData, ScaleOptionsByType } from "chart.js"

export class ChartProcessor {

   tag     : TTag
   block   : TCustomBlock | TIndicationBlock
   result  : TChartSample[]
   interval: number
   fullData: boolean
   datasets: ChartData <'line', (number | Point)[]> ['datasets']
   scales  : _DeepPartialObject<{ [key: string]: ScaleOptionsByType<keyof CartesianScaleTypeRegistry> }>
   data    : {[key: string]: TChartSample[]}
   datum   : string[]
   labels  : ChartData['labels']

   constructor (
      tag     : TTag,
      block   : TCustomBlock | TIndicationBlock,
      result  : TChartSample[],
      interval: number,
      fullData: boolean,
   ) {
      this.tag      = tag
      this.block    = block
      this.result   = result
      this.interval = interval
      this.fullData = fullData
      this.datasets = []
      this.scales   = {
         x: { title: { display: true, text: (interval === 1) ? 'Время' : 'День' } },
      }
      this.data     = {}
      this.datum    = [...new Set (result.map (({ dsample }) => dsample)
      .sort ((a, b) => new Date (a).getTime () - new Date (b).getTime ()))]
      this.labels = this.getLabels ()
   }

   getLabels = (): ChartData['labels'] => {

      return [...new Set (this.datum.map (dsample => {

         return this.interval === 1
             ? this.fullData
               ? new Date (dsample).toLocaleTimeString ('ru-RU')
               : new Date (dsample).toLocaleString ('ru-RU', { hour: 'numeric' })
             : new Date (dsample).toLocaleDateString ('ru-RU')
      }))]
   }

   formatSampleDate = (dsample: Date) => {

      return dsample.toLocaleString ('sv-SE', {
         year  : 'numeric',
         month : '2-digit',
         day   : '2-digit',
         hour  : '2-digit',
         minute: '2-digit',
         ...(this.fullData && { second: '2-digit' })
      }) + (this.fullData ? "." + dsample.getMilliseconds () : '')
   }
 
   processSamples = (samples: TChartSample[]) => {

      //let prevSample = null
 
      samples.forEach (sample => {

         /* if (sample.id_tag === prevSample?.id_tag &&
             prevSample && sample.sample && this.interval === 1) {

            const adjustedSample = this.fullData
                                   ? new Date (new Date (sample.dsample)
                                   .setMinutes(new Date (sample.dsample).getMinutes() - 1))
                                   : new Date (new Date (sample.dsample)
                                   .setHours  (new Date (sample.dsample).getHours  () - 1))
 
            this.data[sample.id_tag].push ({
               sample : prevSample.sample,
               dsample: this.formatSampleDate (adjustedSample),
               id_tag : sample.id_tag
            })
         } */

         this.data[sample.id_tag].push (sample)
         //prevSample = sample
      })
   }

   addChartData = (
      id   : number,
      label : string,
      title: string,
      sign : string,
      color: string,
      id_tagtype: number
   ) => {

      const chartData = this.datum.map (dsample => this.data[id]
      .find (sample => new Date (sample.dsample).getTime () === new Date (dsample).getTime ())?.sample || null)

      this.datasets.push ({
         label          : label,
         data           : chartData,
         borderColor    : color,
         backgroundColor: color,
         yAxisID        : String (id_tagtype),
      })
 
      if (!this.scales[id_tagtype])
         this.scales[id_tagtype] = {
            type    : 'linear',
            display : true,
            position: (Object.keys (this.scales).length === 1) ? 'left' : 'right',
            grid    : { drawOnChartArea: false },
            title   : {
               color  : color,
               display: true,
               text   : `${title} ${sign ? '(' + sign + ')' : ''}`
            },
            ...(id_tagtype === 6 && { min: 0 }),
            ...(id_tagtype === 6 && { max: 100 })
         }
   }
 
   processTagChart = (chart: TTagChart) => {

      const chartTag  = devicesStore.tags
      .find (tag => tag.id == Number(chart.id) && tag.alias == this.block.alias)

      if (!this.data[chartTag.id_tag]) this.data[chartTag.id_tag] = []

      const chartSamples = this.result
      .filter (sample => sample.id_tag === chartTag.id_tag)
 
      if (chartSamples.length > 0) {
         if (chartTag.id_tagtype === 5) this.processSamples (chartSamples)
         this.data[chartTag.id_tag] = chartSamples
      }
 
      if (this.data[chartTag.id_tag]?.length > 0) 
         this.addChartData (
            chartTag.id_tag,
            chart.title ?? chartTag.name,
            chartTag.typename,
            chartTag.sign,
            chart?.color ?? chartTag?.color,
            chartTag.id_tagtype
         )
   }
 
   processDiffChart = (chart: TDiffChart) => {

      const chartTags = chart.tags.map (id => devicesStore.tags
      .find (tag => tag.id == Number (id) && tag.alias == this.block.alias))

      if (!this.data[chart.id]) this.data[chart.id] = []

      const chartSamples = chartTags
      .flatMap (({ id_tag }) => this.result.filter (sample => sample.id_tag === id_tag))
      .sort ((a, b) => new Date (a.dsample).getTime () - new Date (b.dsample).getTime ())
 
      let prevSample
 
      chartSamples.forEach (curSample => {

         if (!prevSample || prevSample.id_tag == curSample.id_tag) {
            prevSample = curSample
         }
         else if (prevSample && curSample &&
                  (new Date (prevSample.dsample).getTime () -
                   new Date (curSample.dsample ).getTime ()) <= chart.interval) {

            const diff = curSample.sample - prevSample.sample
 
            this.data[chart.id].push ({
               sample : Math.abs (diff),
               dsample: this.formatSampleDate (new Date (curSample.dsample)),
               id_tag : chart.id
            })
         }
      })
 
      if (this.data[chart.id]?.length > 0)
         this.addChartData (
            chart.id,
            chart.title ?? chartTags[0]?.name,
            chartTags[0]?.typename,
            chartTags[0]?.sign,
            chart?.color ?? chartTags[0]?.color,
            chartTags[0]?.id_tagtype
         )
   }
 
   processRiftChart = (chart: TRiftChart) => {

      const chartTag = devicesStore.tags
      .find (tag => tag.id == Number (chart.id) && tag.alias == this.block.alias)

      const chartSamples = this.result
      .filter (sample => sample.id_tag === chartTag.id_tag)
 
      this.data[chartTag.id_tag] = []
 
      if (chartSamples.length > 0)
         chartSamples.forEach ((sample, i) => {

            if (i === 0 || i === chartSamples.length - 1)
               this.data[sample.id_tag].push ({
                  sample : sample.sample,
                  dsample: this.datum[i === 0 ? 0 : this.datum.length - 1],
                  id_tag : sample.id_tag
               })
         })
 
      if (Object.keys (this.data)?.length > 0) {

         const chartData = this.datum.map (dsample => this.data[chartTag.id_tag]
         .find (sample => sample.dsample === dsample)?.sample ||
         this.data[chartTag.id_tag][this.data[chartTag.id_tag]?.length - 1].sample)

         const color = chart?.color ?? chartTag?.color
 
         this.datasets.push ({
            data           : chartData,
            borderColor    : color,
            borderWidth    : 1,
            backgroundColor: color,
            normalized     : true,
            yAxisID        : String (chartTag.id_tagtype),
            pointRadius    : 0
         })
 
         if (!this.scales[chartTag.id_tagtype])
            this.scales[chartTag.id_tagtype] = {
               type    : 'linear',
               display : true,
               position: (Object.keys(this.scales).length === 1) ? 'left' : 'right',
               grid    : { drawOnChartArea: false },
               title: {
                  color  : color,
                  display: true,
                  text   : 'Пороги'
               },
            }
      }
   }
 
   processDefaultChart = () => {

      const statusSamples = []
      const colors: string[] = []

      this.result.forEach (sample => {

         if (!this.data[sample.id_tag]) this.data[sample.id_tag] = []

         const sampleTag = devicesStore.tags
         .find (tag => tag.id_tag == sample.id_tag)

         if (sampleTag.id_tagtype === 5) {

            if (!statusSamples[sample.id_tag]) statusSamples[sample.id_tag] = []
            statusSamples[sample.id_tag].push (sample)
         } else this.data[sample.id_tag].push (sample)
      })

      if (statusSamples.length > 0)
         statusSamples.forEach (tagSamples =>
            this.processSamples (tagSamples)
         )

      if (Object.keys (this.data)?.length > 0)
         Object.keys (this.data).forEach (id_tag => {

            const groupTag = devicesStore.tags
            .find (tag => tag.id_tag == Number (id_tag))

            this.addChartData (
               groupTag.id_tag,
               groupTag.name,
               groupTag?.typename,
               groupTag?.sign,
               groupTag?.color,
               groupTag?.id_tagtype
            )
         })

      this.datasets = Object.entries (this.data).map (([key, samplesGroup], i) => {

         const tag = devicesStore.tags
         .find (({ id_tag }) => id_tag === Number (key))
         
         let color

         if (!colors.includes (tag?.color)) {
            colors.push (tag?.color)
            color = tag?.color
         }
         else {
            const rainbow = ['red', 'orange', 'green', 'blue', 'purple', 'pink', 'brown', 'black', 'yellow', 'grey']
            rainbow.some (col => {

               if (!colors.includes (col)) {
                  colors.push (col)
                  color = col
                  return col
               }
            })
         }

         return {
            label          : tag.name,
            data           : this.datum
            .map  (dsample => samplesGroup
            .find (sample => sample.dsample === dsample)?.sample || null),
            borderColor    : color,
            backgroundColor: color,
            yAxisID        : String (tag.id_tagtype),
         }
      })


      /* let firstScale

      this.scales = Object.keys (this.data).reduce ((acc, key, i) => {

         const tag = devicesStore.tags
         .find (({ id_tag }) => id_tag === Number (key))

         if (i === 0) firstScale = tag.id_tagtype

         return {
            ...acc,
            [key]: {
               type    : 'linear',
               stack: 'demo',
               //offset: true,
               stackWeight: 1,
               position: (tag.id_tagtype == firstScale) ? 'left' : 'right',
               grid    : { drawOnChartArea: false },
               title   : {
                  color  : colors[i],
                  display: true,
                  text   : `${name} ${sign ? '(' + sign + ')' : ''}`
               }
            }
         }
      }, {
         x: { 
            title: {
               text   : (this.interval === 1) ? 'Время' : 'День'
            } 
         }
      }) */
   }

   processChartData = () => {

      if (this.block?.charts) {

         this.block.charts.forEach (chart => {

            switch (chart.type) {
               case 'tag':
                  this.processTagChart (chart)
                  break

               case 'diff':
                  this.processDiffChart (chart)
                  break

               case 'rift':
                  this.processRiftChart (chart)
                  break

               default: break
            }
         })
      } else this.processDefaultChart ()

      //console.log (this.labels, this.datasets, this.scales)
      return { labels: this.labels, datasets: this.datasets, scales: this.scales }
   }
}

// Использование
// const chartProcessor = new ChartProcessor (tag, block, result, interval, fullData);
// const chartData = chartProcessor.processChartData ()