import React from 'react'
import { observer } from 'mobx-react'

import { TControl             } from 'models/ControlPanel'
import { ControlFabric        } from "components/Library/ControlFabric/ControlFabric"
import { Select     } from 'grommet'

import { useWaitCommand       } from 'hooks/useWaitCommand'
import { useCorrelation       } from 'hooks/useCorrelation'
import { controllerStateStore } from 'store/ControllerStateStore'

export const ControlSelect = observer (({
   alias,
   control,
   watchdog,
   sendCommand
} : {
   alias      : string,
   control    : TControl,
   watchdog   : boolean,
   sendCommand: (
      a : string,
      c : number,
      v?: number) => void
}) => {

   const { controlCommand, wait } = useWaitCommand (sendCommand)
   const {
      style,
      className,
      disabled,
      label
   } = useCorrelation (control)

   return (
      <ControlFabric wait = { wait }>
         <Select
            name    = 'radio'
            options =  { control.options.map (option => (
               {
                  id   : option?.state,
                  label: label ?? option.label,
                  value: control.look ? eval (control.look) (option?.state) : option?.state,
                  disabled: disabled,
               }
            )) }
            labelKey  = 'label'
            value     = { controllerStateStore.state
            ?.find (state => control.id == state.name)?.value }
            valueKey  = 'id'
            onChange  = { ({ value }) => {
               watchdog
               ? controlCommand (alias, control.options
               .find (option => option.state == value.value)?.command)
               : sendCommand    (alias, control.options
               .find (option => option.state == value.value)?.command)
            } }
            style     = { style }
            className = { className }
            size      = 'small'
         />
      </ControlFabric>
   )
})