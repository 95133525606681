import React, { useState } from 'react'

import {
   Box,
   Button,
   Form,
   FormField,
   TextInput,
} from 'grommet'
import { Key            } from 'grommet-icons'
import { changePassword } from 'api/user'
import { toast          } from 'react-toastify'

import { passwordRulesStrong } from './utils/FormValidation'

export const ChangePassword = ({ onClose }: {onClose: () => void}) => {

   const [formValues, setFormValues] = useState ({
      currentPassword: '',
      newPassword    : '',
      confirmPassword: '',
   })

   const onSubmit = async () => {

      const result = await changePassword ({
         currentPassword: formValues.currentPassword,
         newPassword    : formValues.newPassword
      })

      if (!result?.error_text)
         toast.success ('Ваш пароль успешно обновлён')

      onClose ()
   }

   const confirmPassword = () => {
      return formValues.newPassword === formValues.confirmPassword
      ? undefined
      : { message: 'Пароли не совпадают', status: 'error' }
   }

   return (

      <Box pad = { { horizontal: 'xxsmall' } }>
         <Form
            validate = "blur"
            value    = { formValues }
            onChange = { setFormValues }
            onSubmit = { onSubmit }
            method   = "post"
         >
            <FormField
               htmlFor  = "currentPassword"
               name     = "currentPassword"
               label    = "Текущий пароль"
               validate = { {
                  regexp: new RegExp('.{1,}'),
                  message: 'Поле должно быть заполнено',
               } }
            >
               <TextInput
                  reverse
                  icon        = { <Key /> }
                  id          = "currentPassword"
                  name        = "currentPassword"
                  placeholder = "Введите текущий пароль"
                  type        = "password"
               />
            </FormField>

            <FormField
               htmlFor  = "newPassword"
               name     = "newPassword"
               label    = "Новый пароль"
               validate = { passwordRulesStrong }
            >
               <TextInput
                  reverse
                  icon        = { <Key /> }
                  id          = "newPassword"
                  name        = "newPassword"
                  placeholder = "Введите новый пароль"
                  type        = "password"
               />
            </FormField>

            <FormField
               htmlFor  = "confirmPassword"
               name     = "confirmPassword"
               label    = "Подтвердите пароль"
               validate = { confirmPassword }
            >
               <TextInput
                  reverse
                  icon        = { <Key /> }
                  id          = "confirmPassword"
                  name        = "confirmPassword"
                  placeholder = "Подтвердите новый пароль"
                  type        = "password"
               />
            </FormField>

            <Box
               margin = { { 
                  top   : 'medium',
                  bottom: 'small' 
               } }
            >
               <Button
                  primary
                  fill
                  label = "Обновить пароль"
                  type  = "submit"
               />
            </Box>
         </Form>
      </Box>
   )
}